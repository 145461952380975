import React, { useContext, useState } from 'react';
import {sha256} from "js-sha256";


const PasswordComponent = ({dispatch}) => {
    const [localPassword, setLocalPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        const isDevelopment = process.env.NODE_ENV === 'development';

        // Choose the URL based on the environment
        const url = isDevelopment ?
            'http://0.0.0.0:8080/access-code' :
            'https://shield-prod.eba-z3mkmgni.us-east-1.elasticbeanstalk.com/access-code';

        fetch(url, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "username": "wedding",
                "password": localPassword
            }),
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const loveToken = sha256(localPassword + "123xyz");

                dispatch({ type: 'SET_MANIFEST_URL', payload: data.manifestUrl });

                // Add loveToken to the current URL
                const newUrl = addURLParameter(window.location.href, 'loveToken', loveToken);
                window.history.pushState({}, '', newUrl);

                dispatch({ type: 'AUTHENTICATE', payload: true });
            })
            .catch(
                error => {
                    console.error('wrong access code:', error)
                    dispatch({type: 'SET_ERROR', payload: 'no access'});
                }
            );
    };

    const addURLParameter = (url, key, value) => {
        let newUrl = new URL(url);
        newUrl.searchParams.append(key, value);
        return newUrl.toString();
    }

    return (
        <form className="measure center" onSubmit={handleSubmit}>
            <fieldset
                id="sign_up"
                className="ba b--transparent ph0 mh0"
            >
                <div className="ma3">
                    <label
                        className="db fw6 lh-copy f6 mb1"
                        htmlFor="password"
                    >
                        Enter Access Code:
                    </label>
                    <input
                        className="b pa2 input-reset ba bg-transparent w-50"
                        type="password"
                        name="password"
                        id="password"
                        value={localPassword}
                        onChange={(e) => setLocalPassword(e.target.value)}
                    />
                </div>
                <div className="mt2">
                    <input
                        className="b ph3 pv2 input-reset ba b--black grow pointer f6 dib"
                        type="submit"
                        value="Submit"
                    />
                </div>
            </fieldset>
        </form>
    );
};

export default PasswordComponent;
