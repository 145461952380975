function checkLoveToken() {
    const url = new URL(window.location.href);
    const loveToken = url.searchParams.get('loveToken');
    return loveToken || null;
}

function authenticated(loveToken) {
    if (loveToken != null && loveToken.length > 0) // call server?
        return true
}

const token = checkLoveToken()

export const initialState = {
    loading: true,
    currentImage: null,
    s3ImageManifest: [],
    filterPhotographer: null,
    filterCategory: null,
    photographers: [],
    categories: [],
    highlighted: true,
    error: '',
    authenticated: authenticated(token),
    manifestUrl: '',
    loveToken: token
};

export function reducer(state, action) {
    switch (action.type) {
        case 'SET_LOADING':
            return {...state, loading: action.payload};
        case 'SET_CURRENT_IMAGE':
            return {...state, currentImage: action.payload};
        case 'SET_S3_IMAGE_MANIFEST':
            return {...state, s3ImageManifest: action.payload};
        case 'SET_FILTER_PHOTOGRAPHER':
            return {...state, filterPhotographer: action.payload};
        case 'SET_FILTER_CATEGORY':
            return {...state, filterCategory: action.payload};
        case 'SET_PHOTOGRAPHERS':
            return {...state, photographers: action.payload};
        case 'SET_CATEGORIES':
            return {...state, categories: action.payload};
        case 'SET_FILTER_HIGHLIGHTED':
            return {...state, highlighted: action.payload};
        case 'AUTHENTICATE':
            return {...state, authenticated: action.payload}
        case 'RESET':
            return {...initialState};
        case 'SET_ERROR':
            return {...state, error: action.payload}
        case 'SET_MANIFEST_URL':
            return {...state, manifestUrl: action.payload}
        case 'SET_LOVE_TOKEN':
            return {...state, loveToken: action.payload}
        default:
            throw new Error();
    }
}
