import React from "react";
import TheDate from "./components/TheDate";

const CenterPiece = () => {
    let centerPiece = "https://d381hui2s4gftp.cloudfront.net/wedding/media/Arielle-Thomas-@whiskyspice_ALT3113.jpg";
    let headerStyle = {backgroundImage: `url(${centerPiece})`};
    return (
        <header className="dt w-100 vh-50 tc" >
            <div className="cover dtc v-btm ph3 ph4-m ph5-l white" style={headerStyle}>
                <div className="fw6 mb3 f2-ns f3">Married the 6th of May 2023</div>
            </div>
        </header>
    );
}

export let SaveTheDate = () => {
    return (
        <div className="mt3 vh-foo dt w-100">
            <CenterPiece/>
        </div>
    )
}