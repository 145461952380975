/*jshint esversion: 6*/

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './select.css';
import './switch.css';
import './react-player.css';
import {BrowserRouter as Router, Link, Route, Routes} from "react-router-dom";
import {SaveTheDate} from "./SaveTheDate";
import Music from "./Music";
import Modal from "react-modal";
import {NavBar} from "./NavBar";
import {PhotoGallery} from "./PhotoGallery";
import {ScrollToTop} from "./scrollToTop";

Modal.setAppElement('#root');

const App = () => {
    return (
        <div className="fw6 mb4 mw9 center">
            <Router>
                <NavBar/>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<SaveTheDate/>}
                    />
                    <Route
                        path="/music"
                        element={<Music />}
                    />
                    <Route
                        path="/pictures"
                        element={<PhotoGallery/>}
                    />
                </Routes>
                <ScrollToTop />
            </Router>
        </div>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));