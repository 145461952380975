import React from 'react';
import {EventDetails} from "./components/EventDetails";
import SimoneAndHunter from "./components/SimoneAndHunter";
import TheDate from "./components/TheDate";
import OpenRsvpEmail from "./components/OpenRsvpEmail";
import LinkHome from "./components/LinkHome";
import {Link} from "react-router-dom";
import ReactPlayer from 'react-player';

const Player = () => {
    const song1 = "https://d381hui2s4gftp.cloudfront.net/01+-+Processional+Maid+of+Honor+Bridesmaids+and+Groomsmen+-+Pino+Donaggio+-+Telescope.mp3"
    const song2 = "https://d381hui2s4gftp.cloudfront.net/02+-+Bridal+Processional+Simone's+Entrance+-+Moondog+-+Pastoral.mp3"
    const song3 = "https://d381hui2s4gftp.cloudfront.net/03+-+Recessional+-+Oneohtrix+Point+Never+-+Long+Road+Home.mp3"

    return (
        <div className="">
            <div className="mw6 center">
                <div className='player-wrapper'>
                    <ReactPlayer
                        url={song1}
                        className='react-player'
                        controls={true}
                        width='100%'
                        height='100%'
                        config={{ file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }}}
                    />
                </div>
                <div className="">
                    <div>Processional Maid of Honor Bridesmaids and Groomsmen Enter</div>
                    <div>Pino Donaggio</div>
                    <div>Telescope</div>
                </div>

                <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url={song2}
                        controls={true}
                        width='100%'
                        height='100%'
                        config={{ file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }}}
                    />
                </div>
                <div className="">
                    <div>Bridal Processional Simone's Entrance</div>
                    <div>Moondog</div>
                    <div>Pastoral</div>
                </div>

                {/*
                <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url={song3}
                        controls={true}
                        width='100%'
                        height='100%'
                        config={{ file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }}}
                    />
                </div>
                <div className="">
                    <div>Recessional</div>
                    <div>Oneohtrix Point Never</div>
                    <div>Long Road Home</div>
                </div>
                */}
            </div>
        </div>
    )
}

const Music = () => {
    const dl = "https://d381hui2s4gftp.cloudfront.net/simone_and_hunters_reception_music.zip"
    const dl2 = "https://d381hui2s4gftp.cloudfront.net/wedding_processional_mix.zip"
    const dl3 = "https://d381hui2s4gftp.cloudfront.net/wedding_first_dance.zip"

    const song1 = "https://d381hui2s4gftp.cloudfront.net/01+-+Processional+Maid+of+Honor+Bridesmaids+and+Groomsmen+-+Pino+Donaggio+-+Telescope.mp3"
    const song2 = "https://d381hui2s4gftp.cloudfront.net/02+-+Bridal+Processional+Simone's+Entrance+-+Moondog+-+Pastoral.mp3"
    const song3 = "https://d381hui2s4gftp.cloudfront.net/03+-+Recessional+-+Oneohtrix+Point+Never+-+Long+Road+Home.mp3"

    const onClick = (e) => {
        window.location.href = dl;
        e.preventDefault();
    };

    const onClick2 = (e) => {
        window.location.href = dl2;
        e.preventDefault();
    };

    const onClick3 = (e) => {
        window.location.href = dl3;
        e.preventDefault();
    };

    const onClickSong = (e) => {
        window.location.href = song1;
        e.preventDefault();
    };

    const onClickSong2 = (e) => {
        window.location.href = song2;
        e.preventDefault();
    };

    const onClickSong3 = (e) => {
        window.location.href = song3;
        e.preventDefault();
    };

    return (
        <header className="vh-100 dt w-100">
            <Player/>
            {/*
            <div className="hidden cover dtc v-foo ph3 ph4-m ph5-l">
                <Link
                    to={dl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClick}
                >
                    <div className="tc">simone_and_hunters_reception_music.zip</div>
                </Link>
                <br/>
                <Link
                    to={dl2}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClick2}
                >
                    <div className="tc">wedding_processional_mix.zip</div>
                </Link>
                <br/>
                <Link
                    to={dl3}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClick3}
                >
                    <div className="tc">wedding_first_dance.zip</div>
                </Link>
                <br/>
                <Link
                    to={song1}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClickSong}
                >
                    <div className="tc">01 - Processional Maid of Honor Bridesmaids and Groomsmen - Pino Donaggio - Telescope.mp3</div>
                </Link>
                <br/>
                <Link
                    to={song2}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClickSong2}
                >
                    <div className="tc">02 - Bridal Processional Simone's Entrance - Moondog - Pastoral.mp3</div>
                </Link>
                <br/>
                <Link
                    to={song3}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClickSong3}
                >
                    <div className="tc">03 - Recessional - Oneohtrix Point Never - Long Road Home.mp3</div>
                </Link>
            </div>
            */}
        </header>
    )
}

export default Music

