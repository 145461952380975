import { useState, useEffect } from "react";
import Modal from "react-modal";
import {Link} from "react-router-dom";
import React from "react";

export const ImageOverlay = ({selectedImage, onRequestClose, handleSelect, filteredImages}) => {
    const {firstName, lastName, instagramHandle, fullSrc} = selectedImage;
    const altText = "foobar";
    const [loading, setLoading] = useState(true);

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.4)',  // semi-transparent overlay
            backdropFilter: 'blur(10px)',  // blur effect
            zIndex: 1000,  // ensure modal appears above everything else
        },
        content: {
            top: '5%',
            left: '5%',
            right: '5%',
            bottom: '5%',
            background: 'transparent',
            border: 'none',
            padding: 0,
            margin: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };

    const buttonStyle = {
        position: 'absolute', // This allows the button to float
        top: '50%', // Position in the middle vertically
        transform: 'translateY(-50%)', // Center the element
        padding: '4px',
        fontSize: '2em',
        backgroundColor: 'rgba(255, 255, 255, 0.3)', // Semi-transparent white
        border: '1px solid #555', // Grey border
        borderRadius: '50%', // Make the button circular
        width: '40px', // Width of the button
        height: '40px', // Height of the button
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 10,  // Ensure the button appears above the image
    };

    const goForward = () => {
        const currentIndex = filteredImages.findIndex(image => image === selectedImage);
        const nextIndex = currentIndex + 1 < filteredImages.length ? currentIndex + 1 : 0;
        handleSelect(nextIndex, filteredImages[nextIndex]);
    }

    const goBackward = () => {
        const currentIndex = filteredImages.findIndex(image => image === selectedImage);
        const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : filteredImages.length - 1;
        handleSelect(prevIndex, filteredImages[prevIndex]);
    }

    useEffect(() => {
        const img = new Image();
        img.onload = () => setLoading(false);
        img.src = fullSrc;
        document.body.style.overflow = 'hidden';  // prevent scrolling when modal is open
        return () => {document.body.style.overflow = 'unset';}; // enable scrolling when modal is closed
    }, [fullSrc]);

    return (
        <Modal
            isOpen
            onRequestClose={onRequestClose}
            style={customStyles}
            closeTimeoutMS={2000}
            shouldCloseOnOverlayClick={false}
        >
            {loading ? (
                <div className="loader">Loading...</div>
            ) : (
                <>
                    <div className="">
                        <div>
                            <div
                                onClick={onRequestClose}
                                className="mb2 fr pointer"
                                style={{fontSize: '2em'}}
                            >
                                &times;
                            </div>
                        </div>
                        <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={fullSrc}
                        >
                            <img
                                src={fullSrc}
                                loading="lazy"
                                alt={altText}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '80vh',
                                }}
                            />
                        </Link>
                        <div className="f4 the-cartel flex pv2">
                            <div className="mr1">{`Photo by ${firstName} ${lastName} `}</div>
                            {instagramHandle &&
                            <a href={`https://www.instagram.com/${instagramHandle.slice(1)}`} target="_blank"
                               rel="noopener noreferrer">
                                <div>{` ${instagramHandle}`}</div>
                            </a>
                            }
                        </div>
                        <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={fullSrc}
                        >
                            <button className="mr2 pointer f6 dim ph3 pv2 dib w-100">
                                Open
                            </button>
                        </Link>
                        <button
                            style={{
                                ...buttonStyle,
                                left: '20px',  // Position from the left
                            }}
                            onClick={goBackward}
                        >
                            {`«`}
                        </button>
                        <button
                            style={{
                                ...buttonStyle,
                                right: '20px',  // Position from the right
                            }}
                            onClick={goForward}
                        >
                            {`»`}
                        </button>
                    </div>
                </>
            )}
        </Modal>
    );
};
