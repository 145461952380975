import React, {createContext, useEffect, useReducer} from "react";
import {Gallery} from "react-grid-gallery";
import {initialState, reducer} from "./state/reducer";
import {ImageOverlay} from "./ImageOverlay";
import PasswordComponent from "./components/PasswordComponent";

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const PhotoGalleryContext = createContext();

const Toggle = ({highlighted, dispatch}) => {
    const setFilterHighlighted = async (e) => {
        const checked = e.target.checked
        if (checked === false) {
            dispatch({type: 'SET_LOADING', payload: true});
            dispatch({type: 'SET_FILTER_HIGHLIGHTED', payload: checked})
            await sleep(1000);
            dispatch({type: 'SET_LOADING', payload: false});
        } else {
            dispatch({type: 'SET_LOADING', payload: true});
            dispatch({type: 'SET_FILTER_HIGHLIGHTED', payload: checked})
            dispatch({type: 'SET_FILTER_CATEGORY', payload: ``})
            dispatch({type: 'SET_FILTER_PHOTOGRAPHER', payload: ``})
            await sleep(300);
            dispatch({type: 'SET_LOADING', payload: false});
        }
    }

    return (
        <label className="switch">
            <input
                type="checkbox"
                onChange={e => setFilterHighlighted(e)}
                defaultChecked={highlighted}
            />
            <span className="slider round"/>
        </label>
    )
}

export const PhotoGallery = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (state.authenticated && state.manifestUrl) {
            dispatch({type: 'SET_LOADING', payload: true});
            fetch(state.manifestUrl)
                .then(response => response.json())
                .then(data => {
                    const photos = data.map(item => ({
                        ...item,
                        customOverlay: (
                            <div className="white bg-black f5 quicksand-semi-bold">
                                <div>{`Photo by ${item.firstName} ${item.lastName}`}</div>
                            </div>
                        ),
                    }));

                    dispatch({type: 'SET_S3_IMAGE_MANIFEST', payload: photos});

                    const names = photos.map(img => `${img.firstName} ${img.lastName}`)
                    const uniquePhotographers = Array.from(new Set(names));
                    dispatch({type: 'SET_PHOTOGRAPHERS', payload: uniquePhotographers});

                    const uniqueCategories = Array.from(new Set(photos.map(img => img.category)));
                    dispatch({type: 'SET_CATEGORIES', payload: uniqueCategories});

                    dispatch({type: 'SET_LOADING', payload: false});
                })
                .catch(error => console.error(error));
        }
    }, [state.authenticated, state.manifestUrl]);

    // Fetch manifest if loveToken is present on load
    useEffect(() => {
        if (state.loveToken) {
            const isDevelopment = process.env.NODE_ENV === 'development';

            // Choose the URL based on the environment
            const url = isDevelopment ?
                'http://0.0.0.0:8080/manifest' :
                'https://shield-prod.eba-z3mkmgni.us-east-1.elasticbeanstalk.com/manifest';

            fetch(url, {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "loveToken": state.loveToken
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({ type: 'SET_MANIFEST_URL', payload: data.manifestUrl });
                })
                .catch(
                    error => {
                        console.error('Error getting manifest:', error);
                        dispatch({type: 'SET_ERROR', payload: 'Failed to fetch manifest'});
                    }
                );
        }
    }, [state.loveToken, dispatch]);

    const handleSelect = (idx, photo) => {
        dispatch({type: 'SET_CURRENT_IMAGE', payload: photo});
    };

    const handleCloseModal = () => {
        dispatch({type: 'SET_CURRENT_IMAGE', payload: null});
    };

    const setFilterPhotographer = async (e) => {
        dispatch({type: 'SET_LOADING', payload: true});
        dispatch({type: 'SET_FILTER_PHOTOGRAPHER', payload: e.target.value})
        await sleep(500);
        dispatch({type: 'SET_LOADING', payload: false});
    }


    const setFilterCategory = async (e) => {
        dispatch({type: 'SET_LOADING', payload: true});
        dispatch({type: 'SET_FILTER_CATEGORY', payload: e.target.value})
        await sleep(500);
        dispatch({type: 'SET_LOADING', payload: false});
    }

    const filteredImages = state.s3ImageManifest.filter(img => {
        const photographerFilter = !state.filterPhotographer || `${img.firstName} ${img.lastName}` === state.filterPhotographer;
        const categoryFilter = !state.filterCategory || img.category === state.filterCategory;
        const highlightedFilter = !state.highlighted || img.highlight === true;
        return photographerFilter && categoryFilter && highlightedFilter;
    });

    if (!state.authenticated) {
       return (
         <div className="cf mw9 center mv5 tc">
             <PasswordComponent dispatch={dispatch}/>
             {state.error && (
                 <div className="b red ba b--red ma3 pa3 mw5 center">
                     Wrong Access Code.
                 </div>
             )}
         </div>
       )
    } else {
        return (
            <PhotoGalleryContext.Provider value={{state, dispatch}}>
                <div className="cf mw9 center mv3 pa3 w-80-ns">
                    <div className="fl pv3 mt2 w-20-ns">
                        <Toggle highlighted={state.highlighted} dispatch={dispatch}/>
                        <label className="ml2" style={{position:'relative',top:6}}>
                            Highlights
                        </label>
                    </div>

                    {!state.highlighted ? (
                        <>
                            <div className="fl pv3 w-100 w-40-ns mr3">
                                <select onChange={e => setFilterPhotographer(e)}>
                                    <option value="">Filter Photographer...</option>
                                    {state.photographers.map(photographer => (
                                        <option key={photographer} value={photographer}>{photographer}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="fl pv3 w-100 w-30-ns">
                                <select onChange={e => setFilterCategory(e)}>
                                    <option value="">Filter Category...</option>
                                    {state.categories.map(category => (
                                        <option key={category} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    ) : null}

                </div>

                {!state.loading ? (
                    <Gallery
                        images={filteredImages}
                        onClick={handleSelect}
                        rowHeight={400}
                    />
                ) : (
                    <div className="b ma4 tc">
                        Loading...
                    </div>
                )}

                {filteredImages.length === 0 && !state.loading && (
                    <div className="tc pa3 b1 ma3">
                        No images found.
                    </div>
                )}

                {state.currentImage && (
                    <ImageOverlay
                        selectedImage={state.currentImage}
                        onRequestClose={handleCloseModal}
                        handleSelect={handleSelect}
                        filteredImages={filteredImages}
                    />
                )}
            </PhotoGalleryContext.Provider>
        );

    }


};
